













































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class Awards extends Vue {
    backgroundImage() {
        const img = `${process.env.BASE_URL}bg/awards-bg.jpg`;
        if (img) {
            return {
                "background-image": `url(${img})`
            };
        } else {
            return {};
        }
    }
}
